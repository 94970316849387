import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
// pieces import
import { Button, Paragraph, Image, FlexWrapper } from 'pieces';

export const CardButton = ({ text, ...props }) => {
  const {
    arrow: { publicURL: arrowSrc },
  } = useStaticQuery(graphql`
    query {
      arrow: file(relativePath: { eq: "icons/btn-arrow-icon.svg" }) {
        publicURL
      }
    }
  `);

  return (
    <Button variant='tertiary' {...props}>
      <FlexWrapper sx={{ alignItems: 'center' }}>
        <Image src={arrowSrc} sx={{ width: 48 }} />
        <Paragraph sx={{ ml: 16 }} type='buttonTertiary'>
          {text}
        </Paragraph>
      </FlexWrapper>
    </Button>
  );
};

CardButton.propTypes = {
  text: PropTypes.string.isRequired,
};
