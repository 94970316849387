import React from 'react';
import PropTypes from 'prop-types';
import { Button, Box, Paragraph } from 'pieces';

// This Button gets the styles from the theme. (see `elements.js`)
// Extend if necessary
export const PrimaryButton = React.forwardRef(
  ({ text, children, ...props }, ref) => (
    <Button {...props} ref={ref}>
      <Box
        sx={{
          display: 'inline-block',
          bg: 'primary',
          width: '24px',
          height: '24px',
          borderRadius: '8px 0 0 0',
          mr: '1em',
          boxShadow: '0 0 0',
        }}
      />
      <Paragraph type='buttonPrimary'>{text}</Paragraph>
      <Box>{children}</Box>
    </Button>
  )
);

PrimaryButton.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node,
};

PrimaryButton.defaultProps = {
  children: null,
};
