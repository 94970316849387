import React from 'react';
import PropTypes from 'prop-types';
import { Button, Paragraph } from 'pieces';

export const SecondaryButton = ({ text, ...props }) => (
  <Button variant='secondary' {...props}>
    <Paragraph sx={{ color: 'white' }} type='buttonSecondary'>
      {text}
    </Paragraph>
  </Button>
);

SecondaryButton.propTypes = {
  text: PropTypes.string.isRequired,
};
